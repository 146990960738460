<template>
  <div class="container">
    <div class="header">
      <div class="header-right">
        <div class="header-user-con">
          <div class="user-avator" v-if="user.name">
            <img :src="avatar == '' ? src : avatar" />
          </div>
          <span class="name" id="userName" v-if="user.name">{{
            user.name
          }}</span>
          <!-- <span
            v-if="!user.name"
            class="name"
            style="cursor: pointer; color: #22bcec"
            id="userName"
            @click="login"
            >登录</span
          > -->
          <el-dropdown @command="handleCommand" v-show="user.name">
            <span class="el-dropdown-link">
              <i class="iconExit el-icon-switch-button"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="a">注销登录</el-dropdown-item>
              <!-- <el-dropdown-item command="b">修改密码</el-dropdown-item> -->
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="nav_box">
      <a class="item" :href="gengyun_url" target="_blank">
        <div class="icon"></div>
        <div class="item_name">耕耘</div>
      </a>
      <a class="item" :href="zsk_url" target="_blank" style="margin: 0 56px">
        <div class="icon"></div>
        <div class="item_name">知识库</div>
      </a>
      <a class="item" href="javascript:;" @click="tip()">
        <div class="icon"></div>
        <div class="item_name">邮箱</div>
      </a>
    </div>
  </div>
</template>

<script>
import api from "@/fetch/api";
import { publicFun } from "@/plugins/public";
import {
  authorization,
  setAuthToken,
  getUser,
  getUserInfo,
  getPrivileges,
  login,
  logout,
  domain,
  gengyun_url,
  zsk_url,
  main_url,
  getWxUserByCode,
} from "@/service";
export default {
  name: "guide",
  data() {
    return {
      avatar: "",
      src: require("@/assets/imgs/logo.png"),
      user: {
        userPrincipalName: "",
        name: "",
      },
      code: "",
      gengyun_url: "",
      zsk_url: "",
      code: "",
    };
  },
  created() {
    this.gengyun_url = gengyun_url;
    this.zsk_url = zsk_url;
    this.checkTicket();
  },
  methods: {
    Jump(src) {
      window.location.href = src;
    },
    // login() {
    //   login();
    // },
    tip() {
      // publicFun.message('暂未开放，敬请期待！', 2)
      api.getLoginUrl().then((res) => {
        if (res) {
          window.open(res);
        }
      });
    },
    checkUser() {
      this.user = getUser();
      if (this.user.userPrincipalName == "") {
        login();
      } else {
        // api
        //   .userRole({ userPrincipalName: this.user.userPrincipalName })
        //   .then((res) => {
        //     if (res.code == 0) {
        //       this.avatar = res.data.avatar;
        //       localStorage.setItem("avatar", res.data.avatar);
        //       localStorage.setItem("role", res.data.role);
        //       localStorage.setItem("dept", res.data.dept);
        //     }
        //   });
      }
    },
    checkTicket() {
      let ticket = this.$route.query.ticket;
      if (ticket != null) {
        authorization(ticket).then((response) => {
          if (response.status != 200) return;
          let result = response.data;
          if (result.indexOf("<cas:authenticationSuccess>") < 0) {
            if (window.sessionStorage.getItem("userPrincipalName")) {
              window.location.href = main_url;
            } else {
              login(true);
            }
            return;
          }
          let u1 = result.indexOf("<cas:user>");
          let u2 = result.indexOf("</cas:user>");
          let p1 = result.indexOf("<cas:authorizationToken>");
          let p2 = result.indexOf("</cas:authorizationToken>");
          let r1 = result.indexOf("<cas:authorizationRefreshToken>");
          let r2 = result.indexOf("</cas:authorizationRefreshToken>");
          if (p1 >= 0 && p2 > p1) {
            u1 += "<cas:user>".length;
            let userPrincipalName = result.substring(u1, u2);
            window.sessionStorage.setItem(
              "userPrincipalName",
              userPrincipalName
            );
            document.cookie =
              "userPrincipalName=" +
              userPrincipalName +
              ";domain=" +
              domain +
              ";path=/";
            p1 += "<cas:authorizationToken>".length;
            let authorizationToken = result.substring(p1, p2);
            r1 += "<cas:authorizationRefreshToken>".length;
            let refreshToken = result.substring(r1, r2);
            window.sessionStorage.setItem("refreshToken", refreshToken);
            document.cookie =
              "refreshToken=" + refreshToken + ";domain=" + domain + ";path=/";
            setAuthToken(authorizationToken);
          }

          this.checkUser();
        });
      } else {
        this.code = this.getParameter("code");
        if (this.code) {
          this.getUserInfo(this.code);
        } else {
          let cookieStr = document.cookie;
          let cookieArr = [];
          if (cookieStr) {
            cookieArr = cookieStr.split(";");
            cookieArr.forEach((item) => {
              let arr = item.trim().split("=");
              if (arr[0] == "userPrincipalName") {
                this.user.userPrincipalName = arr[1];
                window.sessionStorage.setItem("userPrincipalName", arr[1]);
              } else if (arr[0] == "name") {
                this.user.name = arr[1];
                window.sessionStorage.setItem("name", arr[1]);
              } else if (arr[0] == "authorizationToken") {
                window.sessionStorage.setItem(
                  "authorizationToken",
                  "Bearer " + arr[1]
                );
              } else if (arr[0] == "refreshToken") {
                window.sessionStorage.setItem("refreshToken", arr[1]);
              }
            });
          }
          this.checkUser();
        }
      }
    },
    getParameter(str) {
      var url = window.location.href.split("?")[1];
      if (!url) {
        return null;
      }
      var arr1 = url.split("&");
      for (var i in arr1) {
        var arr2 = arr1[i].split("=");
        if (str == arr2[0]) {
          return arr2[1];
        }
      }
      return null;
    },
    getUserInfo(code) {
      getWxUserByCode({ code: code }).then((res) => {
        if (res.data.code == 0) {
          if (!res.data.data.userid) {
            let cookieStr = document.cookie;
            let cookieArr = [];
            if (cookieStr) {
              cookieArr = cookieStr.split(";");
              cookieArr.forEach((item) => {
                let arr = item.trim().split("=");
                if (arr[0] == "userPrincipalName") {
                  this.user.userPrincipalName = arr[1];
                  window.sessionStorage.setItem("userPrincipalName", arr[1]);
                } else if (arr[0] == "name") {
                  this.user.name = arr[1];
                  window.sessionStorage.setItem("name", arr[1]);
                } else if (arr[0] == "authorizationToken") {
                  window.sessionStorage.setItem("authorizationToken", arr[1]);
                } else if (arr[0] == "refreshToken") {
                  window.sessionStorage.setItem("refreshToken", arr[1]);
                }
              });
            }
          } else {
            this.user = {
              userPrincipalName: res.data.data.userid,
              name: res.data.data.name,
            };
            window.sessionStorage.setItem(
              "userPrincipalName",
              res.data.data.userid
            );
            window.sessionStorage.setItem("name", res.data.data.name);
            window.sessionStorage.setItem(
              "authorizationToken",
              "Bearer " + res.data.jwt.token
            );
            window.sessionStorage.setItem(
              "refreshToken",
              res.data.jwt.refreshToken
            );
            document.cookie =
              "authorizationToken=Bearer " +
              res.data.jwt.token +
              ";domain=" +
              domain +
              ";path=/";
            document.cookie =
              "userPrincipalName=" +
              res.data.data.userid +
              ";domain=" +
              domain +
              ";path=/";
            document.cookie =
              "name=" + res.data.data.name + ";domain=" + domain + ";path=/";
            document.cookie =
              "refreshToken=" +
              res.data.jwt.refreshToken +
              ";domain=" +
              domain +
              ";path=/";
          }
          this.checkUser();
        }
      });
    },
    handleCommand(command) {
      if (command == "a") {
        logout();
      } else if (command == "b") {
        this.dialogVisible = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100vw;
  height: 100vh;
  background: url("~@/assets/imgs/bg.png") no-repeat;
  background-size: cover;
  .nav_box {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 320px;
    display: flex;
    justify-content: center;
    .item {
      display: block;
      cursor: pointer;
      position: relative;
      width: 320px;
      height: 360px;
      // margin-right: 56px;
      .icon {
        position: absolute;
      }
      .item_name {
        width: 100%;
        position: absolute;
        bottom: 50px;
        text-align: center;
        font-size: 24px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .item:nth-child(1) {
      background: url("~@/assets/imgs/gengyunbg.png") no-repeat;
      background-size: cover;
      .icon {
        top: 101px;
        left: 84px;
        width: 166px;
        height: 161px;
        background: url("~@/assets/imgs/gengyun.png") no-repeat;
        background-size: cover;
      }
    }
    .item:nth-child(2) {
      background: url("~@/assets/imgs/zhishikubg.png") no-repeat;
      background-size: cover;
      .icon {
        top: 101px;
        left: 83px;
        width: 166px;
        height: 161px;
        background: url("~@/assets/imgs/zhishiku.png") no-repeat;
        background-size: cover;
      }
    }
    .item:nth-child(3) {
      background: url("~@/assets/imgs/youxiangbg.png") no-repeat;
      background-size: cover;
      .icon {
        top: 101px;
        left: 74px;
        width: 166px;
        height: 161px;
        background: url("~@/assets/imgs/youxiang.png") no-repeat;
        background-size: cover;
      }
    }
  }
  .header {
    position: fixed;
    background-color: #fff;
    top: 0;
    left: 0;
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: flex-end;
  }
  .header-right {
    display: flex;
    padding-right: 50px;
  }

  .header-user-con {
    display: flex;
    height: 64px;
    align-items: center;
  }
  .user-name {
    margin-left: 10px;
  }
  .name {
    padding-left: 10px;
    font-size: 16px;
    color: #595959;
  }
  .user-avator {
    margin-left: 20px;
  }

  .user-avator img {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .iconExit {
    cursor: pointer;
    color: #fff;
    font-size: 20px;
    color: #595959;
    line-height: 70px;
    margin-left: 20px;
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
